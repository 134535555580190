//
// A template used for creating Blog pages.
//

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  COLOR_GREEN,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  screen,
  COLOR_TEXT_GRAY,
  StyledLink,
  MAIN_HEADING_FONT,
} from '../components/common/variables';
import { removeHTMLTags } from '../components/common/functions';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;

  .blog {
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    padding: 0px 0 60px 0;
    @media ${screen.largeXL} {
      padding: 90px 0 90px 0;
    }

    &__date {
      font-weight: 100;
      font-size: 0.87rem;
      opacity: 0.8;
      padding: 0 35px;
    }

    &__title {
      font-weight: 400;
      color: ${COLOR_GREEN};
      margin: 10px 0 15px 0;
      font-size: 1.25rem;
      padding: 0 35px;
      @media ${screen.medium} {
        font-size: 1.3rem;
      }
      @media ${screen.largeXL} {
        margin: 15px 0 30px 0;
        font-size: 1.4rem;
      }
    }

    &__image {
      max-width: 1040px;
      margin: 0 0 25px 0;
      @media ${screen.largeXL} {
        margin: -98px;
        margin-top: -90px;
        max-width: none;
        margin-bottom: 50px;
      }
      @media ${screen.largeXXL} {
        max-width: 1040px;
        margin: 0 0 25px 0;
      }
    }

    &__content {
      padding-top: 20px;
      max-width: 1040px;
      padding: 0 35px;

      .alignleft,
      .align-left {
        margin-right: 10px;
        margin-bottom: 10px;
        display: block !important;
        float: none !important;
        @media ${screen.medium} {
          margin-bottom: 0;
          display: inline !important;
          float: left !important;
        }
      }

      .alignright,
      .align-right {
        margin-left: 10px;
        display: block !important;
        float: none !important;
        @media ${screen.medium} {
          margin-bottom: 0;
          display: inline !important;
          float: right !important;
        }
      }

      .aligncenter,
      .align-center {
        display: block;
        float: none;
        margin: 0 auto;
      }
      .wp-caption-text {
        font-size: 0.7rem;
        line-height: 1.3;
      }
      .wp-caption {
        font-size: 1rem;
        font-weight: 300;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          font-size: 1.1rem;
        }
      }

      p {
        font-size: 1rem;
        margin-bottom: 20px;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          font-size: 1.1rem;
          margin-bottom: 30px;
        }
      }

      h1,
      h3,
      h4,
      h5,
      h6 {
        color: ${COLOR_GREEN};
        margin: 10px 0;
      }

      h2 {
        font-size: 5.4rem;
      }

      strong {
        color: ${COLOR_GREEN};
      }

      a {
        color: ${COLOR_GREEN};
      }

      ol {
        padding: 0;
        li {
          color: ${COLOR_GREEN};
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.35;
          ${MAIN_HEADING_FONT};
        }
      }

      ul {
        padding-left: 20px;
        li {
          font-size: 1rem;
          margin-bottom: 5px;
          font-weight: 300;
          line-height: 1.5;
          color: ${COLOR_TEXT_GRAY};
          @media ${screen.medium} {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }
        }
      }

      img {
        /* width: 100%; */
        height: auto;
        max-width: 100%;
      }
    }

    &__navigate-btns {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      padding: 0 35px;
      clear: both;
      @media ${screen.large} {
        justify-content: flex-start;
        padding: 35px 35px 0 35px;
      }
      .prev {
        margin-right: 12px;
        text-align: center;
        display: block;
        width: 50%;
        @media ${screen.medium} {
          width: auto;
        }
      }
      .next {
        margin-left: 12px;
        text-align: center;
        display: block;
        width: 50%;
        @media ${screen.medium} {
          width: auto;
        }
      }
    }
  }
`;

const Blog = (props) => {
  const { acfBlog } = props.data.blog;
  if (!acfBlog.mainImage) {
    console.error(`[Alert] [Blog] ${props.data.blog.title} has no main_image`);
  }
  const title = props.data.blog.title;
  const content = acfBlog.content;
  const createdDate = moment(props.data.blog.date).format('MMMM DD, YYYY');
  const image =
    acfBlog.mainImage &&
    acfBlog.mainImage.localFile.childImageSharp.gatsbyImageData;
  const next = props.pageContext.next;
  const prev = props.pageContext.prev;

  const pageTitle = (acfBlog.seo && acfBlog.seo.pageTitle) || title;
  const pageDescription =
    (acfBlog.seo && acfBlog.seo.description) ||
    removeHTMLTags(content).split(' ').slice(0, 30).join(' ') + '...';

  return (
    <div>
      <Layout>
        <SEO
          title={pageTitle}
          description={pageDescription}
          image={image && image.images.fallback.src}
        />

        <Wrapper>
          <div className="blog">
            {image && (
              <div className="blog__image">
                <GatsbyImage
                  image={image}
                  alt={
                    acfBlog.mainImage.altText ||
                    'Source Separation Systems Read Blog'
                  }
                />
              </div>
            )}
            <p
              className="blog__date"
              dangerouslySetInnerHTML={{ __html: createdDate }}
            />
            <h2
              className="blog__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="blog__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="blog__navigate-btns">
              {prev ? (
                <StyledLink className="prev" to={`/blog/${prev}`}>
                  Previous
                </StyledLink>
              ) : null}
              {next ? (
                <StyledLink
                  className="next"
                  transparent="true"
                  to={`/blog/${next}`}
                >
                  Next
                </StyledLink>
              ) : null}
            </div>
          </div>
        </Wrapper>
      </Layout>
    </div>
  );
};

export default Blog;

export const blogQuery = graphql`
  query ($id: String!) {
    blog: wpPost(id: { eq: $id }) {
      title
      date
      acfBlog {
        content
        seo {
          pageTitle
          description
        }
        mainImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
